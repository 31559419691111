import React from 'react';
import css from './bid-box.module.less';
import {BidList, Info, LeftBox} from "./index";
import {TENDER_URL} from "../../../common/static_number";
import {PickItem} from "./pick-item";


/** 精品项目 */
export const BidBox =(props)=>{

    const {data={}} = props;
    const goDetail = (type,opt)=>{
        switch (type) {
            case 'market': //招标信息
                window.open(TENDER_URL + '/market/view/' + opt.project_sn);
                break;
            case 'info': //通知公告
                window.open(TENDER_URL + '/category/148/178/view/' + opt.id);
                break;
            case 'news': //新闻资讯
                window.open(TENDER_URL + '/category/148/177/view/' + opt.id);
                break;
            default:

        }

    }
    //     项目名称                                 采购产品                     需求数量                         林地地区                     报名截止日期
    return (
        <div className={css.box}>
            <LeftBox>
                {data.tender1.map(item=><Info goDetail={()=>goDetail('info',item)} key={item.id} item={item} name={item.title} type={'通知公告'} style={{marginTop: 0}}/>)}
                {data.tender2.map(item=><Info goDetail={()=>goDetail('news',item)} key={item.id} item={item} name={item.title} type={'新闻资讯'}/>)}
                <div className={css.projectList}>
                    {data.tender3.map((opt)=><PickItem goDetail={()=>goDetail('market',opt)} key={opt.id} item={opt}/>)}
                </div>
            </LeftBox>
            <div className={css.right}>
                <BidList items={data.tender4} goDetail={goDetail}/>
            </div>

        </div>
    );

}