import React, {Fragment, useState,useEffect} from 'react';
import {NavLink, useHistory} from 'react-router-dom';
import {useEffectOnce} from 'react-use';
import {message} from 'antd';
import {CaretDownOutlined} from '@ant-design/icons';
import {useIsLogin, useUserInfo} from '../hook';
import {ServicesModel} from '../common';
import cookie from 'react-cookies';
import {store} from '../helps';
import css from './top-header.module.less';
import avatar from 'aliasimgurl/default_avatar.png';
import {PROJECT_URL, TENDER_URL} from "../common/static_number";

export const TopHeader = (props)=>{
    const [data] = useUserInfo();

    const [isLogin] = useIsLogin();

    const [city,setCity] = useState('加载中...');
    const [timer,setTimer] = useState(null);
    const handleLogout = ()=>{
        ServicesModel.doLogout().subscribe({
            next:res=>{
                store.clearAll();
                const domain = document.domain === 'localhost' ? document.domain : "."+document.domain.split('.').slice(-2).join('.')
                cookie.remove('token', { domain,path: '/' });
                message.success('退出成功！',3, window.location.href = '/');

            },
            error:err=>{},
        });
    }

    useEffectOnce(()=>{
        function updateCity() {
            let _city = window.currentCity;
            setCity(_city||'加载中...');
            if (!_city) {
                let _timer = setTimeout(()=>{
                    updateCity();
                },1000);
                setTimer(_timer);
            };
        };
        updateCity();
        return ()=>{
            if (timer) {
                clearInterval(timer);
            }
        }
    });

    return (
        <div className={ css['top-header'] }>
            <div className="container">
                <div className={ css.wraper }>
                    <div className={ css.welcome }>
                        <div>
                            您好，欢迎来到中国林业产业服务与交易平台！ 热线电话：010 - 53673671
                        </div>
                    </div>
                    <div className={ css.ext }>
                        {
                            isLogin?
                            <Fragment>
                                <a className={css.userinfo} href={'/account/setting'}>
                                    <img src={data.avatar || avatar} alt=""/> {data.mobile} &nbsp;&nbsp;
                                </a>
                                <div className={css.menu}>
                                    <a href={'/account/home'} className={css.menuTitle} target={"_block"}>个人中心 <CaretDownOutlined /></a>
                                    <div className={css.menuBox}>
                                        <a href={"/account/setting"}>账户设置</a>
                                        <a href={"/account/work"}>消息通知</a>
                                        {/*<a href={"/account/project"}>林地管理</a>*/}
                                        {/*<a href={TENDER_URL + "/account/project"}>招投标管理</a>*/}
                                        {/*<a href={PROJECT_URL + "/account/project"}>项目库管理</a>*/}
                                        {/*<a href={"/account/collect"}>收藏夹</a>*/}
                                    </div>
                                </div>
                                <i></i>
                                <a onClick={ handleLogout }>退出</a>
                            </Fragment>:
                            <Fragment>
                                <NavLink to="/login">登录</NavLink>
                                <i></i>
                                <NavLink to="/reg">注册</NavLink>
                            </Fragment>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}