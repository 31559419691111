import React from 'react';
import css from './index.module.less';

import {Input,Button,Form,message} from 'antd';
import {AddressItem} from "../../../components";
import {ServicesModel} from "../../../common";
import aboutBg from 'aliasimgurl/about_bg.png';

/** 联系我们 */
export const Contact = (props) => {
    const [ideaForm] = Form.useForm();
    /** 联系我们 */
    const saveIdea = ()=>{
        ideaForm.validateFields().then((v)=>{
            const [province_id, city_id, county_id] = v.area;
             ServicesModel.addProposal({type:4,province_id, city_id, county_id,...v}).subscribe({
                next:res=>{
                    message.success('提交成功',1,()=>{
                        ideaForm.resetFields();
                    })
                }
            })
        })
    };


    return (
        <div className={css.body}>
            <img src={aboutBg} className={css.bg} alt=""/>
            <div className={css.box}>
                <div className={css.header}>
                    联系方式 <span> /CONTACT</span>
                </div>
                <div className={css.content}>
                    <div className={css.leftBox}>
                        <div className={css.row}>
                            <span>联系人:</span>
                            市场服务部
                        </div>
                        <div className={css.row}>
                            <span>联系QQ:</span>
                            2405895217
                        </div>
                        <div className={css.row}>
                            <span>联系电话:</span>
                            400-660-0813
                        </div>
                        <div className={css.row}>
                            <span>联系邮箱:</span>
                            service@china-forest.com
                        </div>
                    </div>
                    <div className={css.rightBox}>
                        <Form form={ideaForm}  labelCol={{span:9}} >
                            <Form.Item
                                label="姓名"
                                name="name"
                                rules={[{required: true, message: '请输入您的姓名' }]}
                            >
                                <Input autoComplete="off" placeholder="请输入您的姓名"/>
                            </Form.Item>
                            <Form.Item
                                label="电话"
                                name="mobile"
                                rules={[{required: true, message: '请输入您的联系电话' }]}
                            >
                                <Input autoComplete="off" placeholder="请输入您的联系电话"/>
                            </Form.Item>
                            <Form.Item label={'城市'} name={'area'}
                                       rules={[{required: true, message: '请选择所在城市',},({ getFieldValue }) => ({
                                           validator(rule, value) {
                                               if(value && value[3]){
                                                   return Promise.reject('请选择所在城市');
                                               }
                                               return Promise.resolve();
                                           },
                                       })]}>
                                <AddressItem size="middle"/>
                            </Form.Item>
                        </Form>
                    </div>
                </div>
                <div className={css.btns}>
                    <Button type={"primary"} onClick={saveIdea} className={css.btn}>立刻合作</Button>
                </div>
            </div>

        </div>
    )
}
