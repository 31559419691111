import React from 'react';
import css from './index.module.less';
import {Form, Input, Modal,Select} from 'antd';

import classNames from 'classnames';
import {Box} from "./components/box";
import Service01 from 'aliasimgurl/assess_service_01.png';
import Service02 from 'aliasimgurl/assess_service_02.png';
import Service03 from 'aliasimgurl/assess_service_03.png';
import {AddressItem} from "../../components";
import {ServicesModel} from "../../common";
import {useDataApi, useIsLogin} from "../../hook";
import * as R from 'ramda';
import {useHistory} from "react-router-dom";

const {Option} = Select;
/** 林权评估 */
export const Assess = (props) => {
    const [ideaForm] = Form.useForm();
    const [assessForm] = Form.useForm();
    const {push} = useHistory();
    const [{ ...areaData }] = useDataApi({url:'/api/getCity',method: 'post',data:{pid:0,type:2},initialData: []});

    /** 联系我们 */
    const saveIdea = ()=>{
        ideaForm.validateFields().then((v)=>{
            const [province_id, city_id, county_id] = v.area;
            ServicesModel.addProposal({type:6,province_id, city_id, county_id,...v}).subscribe({
                next:res=>{
                    Modal.success({
                        title: '您提交的咨询信息已发送成功，请等待工作人员与您联系!',
                        content: `如有其它疑问请致电 400-660-0813`,
                    });
                    ideaForm.resetFields();
                }
            })
        })

    };
    const  [isLogin] = useIsLogin();
    const isEmpty = n=> { return !!n};
    const sendAssess = ()=>{
        const v = assessForm.getFieldsValue();
        if(isLogin){
            push({pathname:'/addAssess',state:{...R.filter(isEmpty,v)}})
        }else{
            Modal.warning({
                title:'温馨提示',
                content: '您还未登录，是否现在登录？',
                okText:'去登录',
                cancelText:'取消',
                maskClosable: true,
                onOk: ()=>{
                    window.location.href = '/login'
                },
                onCancel:()=>{

                }
            });
        }

    };
    return (
        <div style={{background: '#fff'}} className={css.index}>
            <div className={classNames(css.banner)}>
                <div className={classNames('container', css.header)}>
                    <div className={css.leftWarp}>
                        <div className={css.title}>
                            专业林权评估平台 <br/>服务全国的林业行业用户
                        </div>
                        <div className={css.subtitle}>为林业行业的参与者、关注者提供全面、深入、专业的<br/>行业资讯及行情数据</div>
                    </div>
                    <div className={css.rightWarp}>
                        <div className={css.formTitle}>
                            填写林权信息，帮您快速评估
                        </div>
                        <Form form={assessForm} className={css.form}>
                            <div className={css.formLeft}>
                                <Form.Item  name={'province_id'} >
                                    <Select size="large" placeholder="请选择林地地区"
                                    >
                                        {
                                            [...areaData.data].map(opt=>
                                                <Option  key={ opt.id } value={ opt.id }> { opt.name }</Option>
                                            )
                                        }
                                    </Select>
                                </Form.Item>
                                <Form.Item name="years">
                                    <Input size="large" autoComplete="off" placeholder="请输入剩余使用年限（年）" suffix={'年'} />
                                </Form.Item>
                                <Form.Item
                                    name="area"
                                >
                                    <Input size="large" autoComplete="off" placeholder="请输入林地面积（亩）" suffix={'亩'} />
                                </Form.Item>
                            </div>
                        </Form>
                        <div className={css.assessBtn} onClick={sendAssess}>开始评估 </div>
                    </div>
                </div>
            </div>

            <div className={css.indexWarp}>
                <Box title={'服务内容'} style={{background:"#F5F5F5"}}>
                    <div className={css.serviceList}>
                        <div className={css.service}>
                            <img src={Service01} alt=""/>
                            <div className={css.title}>
                                升值空间评估
                            </div>
                            <div className={css.subtitle}>
                                林权、林地的价值评估是林权流转、抵押贷款的核心。区位优势、林地类别、水源及交通情况等信息是林权、林地价值评估的基础。                            </div>
                        </div>
                        <div className={css.service}>
                            <img src={Service02} alt=""/>
                            <div className={css.title}>
                                抵押贷款评估
                            </div>
                            <div className={css.subtitle}>
                                按照银行、金融机构对于林权抵押贷款申请材料的要求，具备抵押条件的组织或个人需要提供相关的评估证书和资料。
                            </div>
                        </div>
                        <div className={css.service}>
                            <img src={Service03} alt=""/>
                            <div className={css.title}>
                                报价评估
                            </div>
                            <div className={css.subtitle}>
                                基于大数据的分析手段，将根据您的报价对您的林权、林地“流转可行性或抵押贷款价值”进行评估,                            </div>
                        </div>
                    </div>
                </Box>
                <Box title={'服务流程'}>
                    <div className={css.flow}>
                        <div className={css.flowItem}>
                            <div className={css.iconIndex}>
                                <div className={css.icon}/>
                            </div>
                            <div className={css.flowTitle}>1.提出需求</div>
                        </div>
                        <div className={css.flowIcon}/>
                        <div className={css.flowItem}>
                            <div className={css.iconIndex}>
                                <div className={css.icon}/>
                            </div>
                            <div className={css.flowTitle}>2.协调专家</div>
                        </div>
                        <div className={css.flowIcon}/>
                        <div className={css.flowItem}>
                            <div className={css.iconIndex}>
                                <div className={css.icon}/>
                            </div>
                            <div className={css.flowTitle}>3.达成合作意向</div>
                        </div>
                        <div className={css.flowIcon}/>
                        <div className={css.flowItem}>
                            <div className={css.iconIndex}>
                                <div className={css.icon}/>
                            </div>
                            <div className={css.flowTitle}>4.支付保证金</div>
                        </div>
                        <div className={css.flowIcon}/>
                        <div className={css.flowItem}>
                            <div className={css.iconIndex}>
                                <div className={css.icon}/>
                            </div>
                            <div className={css.flowTitle}>5.安排日程</div>
                        </div>
                    </div>

                </Box>
                <div className={css.contact}>
                    <div className={'container'} >
                        <div className={css.contactTitle}>
                        留下您的基本信息，了解更多
                    </div>
                        <Form form={ideaForm} className={css.form}>
                            <div className={css.formLeft}>
                                <Form.Item
                                    label="姓名"
                                    name="name"
                                    rules={[{required: true, message: '请输入您的姓名' }]}
                                >
                                    <Input autoComplete="off" placeholder="请输入您的姓名"/>
                                </Form.Item>
                                <Form.Item
                                    label="电话"
                                    name="mobile"
                                    rules={[{required: true, message: '请输入您的电话' }]}
                                >
                                    <Input autoComplete="off" placeholder="请输入您的电话"/>
                                </Form.Item>
                                <Form.Item label={'城市'} name={'area'}
                                           rules={[{required: true, message: '请选择所在城市',},({ getFieldValue }) => ({
                                               validator(rule, value) {
                                                   if(value && value[3]){
                                                       return Promise.reject('请选择所在城市');
                                                   }
                                                   return Promise.resolve();
                                               },
                                           })]}>
                                    <AddressItem size="middle"/>
                                </Form.Item>
                            </div>
                            <div className={css.formRight}>
                                <Form.Item
                                    label="咨询详情"
                                    name="content"
                                    extra={'注：限500字以内，我们将免费为您解答。'}
                                    rules={[{required: true, message: '请输入您要咨询的问题' }]}
                                >
                                    <Input.TextArea autoSize={{minRows:6}} autoComplete="off" placeholder="请输入您要咨询的问题"/>
                                </Form.Item>
                            </div>

                        </Form>
                        <div className={css.btn} onClick={saveIdea}>立即咨询 </div>
                    </div>
                </div>
            </div>
        </div>
    );
}