import React, {Fragment, useEffect, useState} from 'react';
import {Button, Checkbox, Form, Input, Modal, Radio} from 'antd';
import {BusinessType} from '../../../helps';
import {AddressItem, UploadImage} from '../../../components';
import css from './userauth.module.less';
import {ServicesModel} from "../../../common";
import {SUPPLIER_AND_INVITER} from "../../../common/static_number";
import {IndustryWarp, StatusWarp} from "../components";

export const UserAuthForm = (props)=>{
    const { onSubmit ,data} = props;
    const [category] = useState(data.apply_type);
    const [params,setParams] = useState({});
    useEffect(()=>{
        const {province_id,city_id,county_id} = data;
        setParams({isLoading:true,...params,...{province_id,city_id,county_id}})
    },[data]);
    const handleFinish = value =>{
        console.log('=========',value);
        const {business_type,...v} = value;
        const [province_id,city_id,county_id,] = value.site||[];
        console.log('=========',v);

        ServicesModel.authForm({identity_type:category,province_id,city_id,county_id,business_type: typeof(business_type)=='string' ? business_type.split(','):business_type,...v }).then(({data: {data, msg, code}})=>{
            if(code === 200){
                onSubmit({...params,...value});
            }else{
                Modal.error({
                    title:'错误信息',
                    content:msg,
                    width:'350px'
                })
            }
        })
    };
    return(
        <Fragment>
            {params.isLoading &&
                <Form onFinish={ handleFinish } initialValues={data} labelCol={{span:6}} className={ css.form }>
                    <Form.Item
                        label="真实姓名"
                        placeholder="请输入真实姓名"
                        name="legal_person"
                        rules={[{ required: true, message: '请输入真实姓名' }]}
                    >
                        <Input  autoComplete="off"/>
                    </Form.Item>
                    <Form.Item
                        label="身份证号"
                        placeholder="请输入身份证号"
                        name="legal_identity"
                        rules={[{ required: true, message: '请输入身份证号' }]}
                    >
                        <Input  autoComplete="off"/>
                    </Form.Item>
                    {/*<Form.Item*/}
                    {/*    label="行业分类"*/}
                    {/*    name="business_type"*/}
                    {/*    placeholder=""*/}
                    {/*    rules={[{ required: true, message: '请选择行业分类' }]}*/}
                    {/*>*/}
                    {/*    <IndustryWarp label={'林地状态'} />*/}
                    {/*</Form.Item>*/}
                    {
                        1 == category &&
                        <Fragment>
                            <Form.Item
                                label="公司名称"
                                placeholder="请输入公司名称"
                                name="company_name"
                                rules={[{ required: true, message: '请输入公司名称' }]}
                            >
                                <Input  autoComplete="off" />
                            </Form.Item>
                            <Form.Item
                                label="注册资金"
                                placeholder="请输入"
                                name="reg_capital"
                                rules={[{ required: true, message: '请输入注册资金' }]}
                            >
                                <Input addonAfter="万元" autoComplete="off"/>
                            </Form.Item>
                            <Form.Item
                                label="注册地址"
                                placeholder="请选择地址"
                                name={'site'}
                                rules={[{ required: true, message: '请选择注册地址' }]}
                            >
                                <AddressItem size="default" />
                            </Form.Item>
                        </Fragment>
                    }
                    <Form.Item
                        label="邮箱"
                        name="email"
                        placeholder=""
                        rules={[{
                            type: 'email',
                            message: '邮箱格式不正确',
                        },{required: true, message: '请输入邮箱' }]}
                    >
                        <Input  autoComplete="off"/>
                    </Form.Item>
                    {  1 == category?
                        <Form.Item label="企业工商营业执照" name={'business_licence_cert'} rules={[{required:true,message:'请上传企业工商营业执照'}]}>
                            <UploadImage uploadNum={1} value={params.business_licence_cert||[]}/>
                        </Form.Item>:
                        <Fragment>
                            <Form.Item label="身份证正反面" name={'legal_identity_cert'} rules={[{required:true,message:'请上传身份证正反面照片'}]}>
                                <UploadImage uploadNum={2} value={params.legal_identity_cert||[]}/>
                            </Form.Item>
                            {/*<Form.Item label="手持身份证" name={'legal_identity_cert_un'} rules={[{required:true,message:'请上传手持身份证照片'}]}>*/}
                            {/*    <UploadImage uploadNum={1} value={params.legal_identity_cert_un||[]}/>*/}
                            {/*</Form.Item>*/}
                        </Fragment>
                    }
                    <Form.Item className={ css.btnWrap }>
                        <Button  type="primary" htmlType="submit">提交</Button>
                    </Form.Item>
                </Form>
            }
        </Fragment>

    );

}
