import React from 'react';
import css from './footer.module.less';
import {useDataApi} from "../hook";



export const Link = ()=>{

    const [{data,isLoading}] = useDataApi({url:'/api/linklist',method:'post',initialData:[]});
    return (
        <div className={ css.footer }>
            <div className="container">
                <div className={css.LA}>
                    <div className={css.title}>
                        友情链接
                    </div>
                    {!isLoading && <div className={css.linkBox}>
                        { data.map((item)=><a href={item.url} target={"_block"}>{item.title}</a>) }
                    </div>
                    }

                </div>
            </div>
        </div>
    );
}