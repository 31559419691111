import React from 'react';
import css from './index.module.less';
import {TitleBox} from "../components/titleBox";

/** 广告服务 */
export const Adve = (props) => {
    return (
        <div className={css.body}>
            <TitleBox title={'广告服务'} />
            <div className={css.content}>
               <p>
                   <span className={css.label}>广告形式:</span> 本站提供广告条及各种形式的广告服务(下面是几种常见的广告形式)
               </p>
                <p>
                    <span className={css.label}>Banner:</span> Banner 一般翻译为网幅广告、旗帜广告、横幅广告等。一个表现商家广告内容的图片，放置在广告商的页面上，是互联网广告中最基本的广告形式，尺寸是580*314像素，一般是使用GIF格式的图像文件，可以使用静态图形，也可用多帧图像拼接为动画图像。
                </p>
                <p>
                    <span className={css.label}>Button:</span> 即按钮广告，一般是280*110像素、250*250像素、120*60像素，甚至更小。由于图形尺寸小，故可以被更灵活的放置在网页的任何位置。
                </p>
                <p>
                    <span className={css.label}>文字链:</span> 8-20字内的文字链广告位，适合初创型企业进行宣传。
                </p>
                <p>
                   <span className={css.label}>广告联系:</span> QQ:2405895217     联系电话：400-660-0813 <br/>
                   <span className={css.hint}>（本站不接受任何与邮件营销、欺诈、木马、博彩等非法信息相关的广告业务，除以上联系方式外，任何自称本站广告QQ与联系电话均为假冒）
</span>
                </p>
            </div>
        </div>
    )
}